import { $notify, getMessageFromError, $setParams } from "@/plugins/Helpers";

const defaultParams = {
    page: 1,
    page_size: 10,
    query: "",
    next: "",
};

export default {
    namespaced: true,
    state: {
        forms: {},
        form: [],
    },
    actions: {
        fetchForms: ({ rootState, commit }, params = {}) => {
            params = { ...defaultParams, ...params };
            return axios
                .get(
                    `/${
                        rootState.auth.organization.organization
                    }/intakes/forms/all/${$setParams(params)}`
                )
                .then((res) => {
                    commit("setForms", res.results);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        retrieveForm: ({ rootState, commit }, id) => {
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/intakes/forms/retrieve/${id}/`
                )
                .then((res) => {
                    commit("setForm", res);
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        createForm: ({ rootState }, payload) => {
            return axios
                .post(
                    `/${rootState.auth.organization.organization}/intakes/forms/create/`,
                    payload
                )
                .then((res) => {
                    $notify.success("New form created successfully.");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },

        updateForm: ({ rootState }, payload) => {
            return axios
                .put(
                    `/${rootState.auth.organization.organization}/intakes/forms/update/${payload.id}/`,
                    payload
                )
                .then((res) => {
                    $notify.success("Form updated successfully.");
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
        downloadFormbuilder: ({ rootState },id)=>{
            return axios
                .get(
                    `/${rootState.auth.organization.organization}/intakes/forms/download/${id}/`
                )
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    getMessageFromError(error);
                });
        },
    },
    mutations: {
        setForms: (state, payload) => {
            state.forms = payload;
        },
        clearForms: (state) => {
            state.forms = {};
        },
        setForm: (state, payload) => {
            state.form = payload;
        },
        clearForm: (state) => {
            state.form = [];
        },
        reset: (state) => {
            state.forms = {};
            state.form = [];
        },
    },
};
